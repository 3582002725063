import axios from 'axios'
import type { NextRouter } from 'next/router'

import { sendRequest, getECommerceDomainByEnv, getLocaleStr, normalizeFileNameStr } from 'utils/utils'
import { codeToLocale } from 'utils/language'

import type { LanguageLocale, LanguageCode } from 'utils/language'
import type _Infra from 'mobx/Infra'
import type _User from 'mobx/User'

export interface DeepLinkProps {
	Infra: typeof _Infra
	User: typeof _User
	deepLinkId: string
	iosApplicationStoreURL: string
	androidApplicationStoreURL: string
}

/**
 * TODO-SERGEY:
 * Duplicate of an enum that is located in ecommerse-server/src/types/DeepLink.type.tx
 * Will be resolved after a proper migration to the nx monorepo
 */

enum DestinationType {
	home = 'home',
	couponWallet = 'couponWallet',
	deals = 'deals',
	contactUs = 'contactUs',
	staticMenu = 'staticMenu',
	storeLocator = 'storeLocator',
}

/**
 * TODO-SERGEY:
 * Duplicate of an interface that is located in ecommerse-server/src/types/DeepLink.tx
 * Will be resolved after a proper migration to the nx monorepo
 */
interface DeepLinkData {
	slug: string
	destination: {
		type: DestinationType
		storeId?: string
	}
	attachment: {
		type: string
		couponCode: string
		itemCoupon: string
	}
	storeId: string
	utm?: {
		id?: string
		source: string
		medium: string
		campaign?: string
		term?: string
		content?: string
	}
	webOnly?: boolean
}

type UtmKeys = keyof DeepLinkData['utm']

const REDIRECTION_TO_APPLICATION_STORE_TIMEOUT = 1500

const getRedirectionPath = (destinationType: DestinationType | undefined, lang: string) => {
	switch (destinationType) {
		case DestinationType.contactUs:
			return `/${lang}/contact-us`

		case DestinationType.staticMenu:
			return `/${lang}/menu`

		case DestinationType.storeLocator:
			return `/${lang}/store-locator`

		case DestinationType.couponWallet:
			return `/${lang}/coupons`

		case DestinationType.deals:
			return `/${lang}/deals`

		case DestinationType.home:
			return `/home`

		default:
			console.error('Invalid destination type')
			return `/home`
	}
}

const generateUtmQueryParameters = (utm: DeepLinkData['utm']): URLSearchParams => {
	if (!utm) {
		return new URLSearchParams()
	}

	const utmQueryParameters = new URLSearchParams()
	Object.keys(utm).forEach((key) => {
		if (utm[key as UtmKeys]) {
			utmQueryParameters.set(`utm_${key}`, utm[key as UtmKeys])
		} else {
			console.error(`Invalid UTM key: ${key}`)
		}
	})

	return utmQueryParameters
}

const getNormalizedStoreName = async (storeTitle: Partial<Record<LanguageLocale, string>>, language: LanguageCode) => {
	const langCode = codeToLocale[language as LanguageCode] || 'en_US'
	const storeNameTranslated = getLocaleStr(storeTitle, langCode)

	return normalizeFileNameStr(storeNameTranslated)
}

export const openMobileAppWithAppSchemeOrRedirectToStore = ({
	bundleId,
	deepLink,
	applicationStoreURL,
}: {
	bundleId: string | undefined
	deepLink: string
	applicationStoreURL: string
}) => {
	const start = new Date().getTime()
	const deepLinkElement = document.createElement('a')

	// Navigate to the application if installed
	try {
		deepLinkElement.href = `${bundleId}:/${deepLink}`
		deepLinkElement.style.display = 'none'
		document.body.appendChild(deepLinkElement)
		deepLinkElement.click()
	} catch (error) {
		document.body.removeChild(deepLinkElement)
		window.location.replace(applicationStoreURL)

		return
	}

	setTimeout(() => {
		const end = new Date().getTime()

		if (end - start < REDIRECTION_TO_APPLICATION_STORE_TIMEOUT + 500) {
			window.location.replace(applicationStoreURL)
		}

		document.body.removeChild(deepLinkElement)
	}, REDIRECTION_TO_APPLICATION_STORE_TIMEOUT)
}

export const buildRedirectionLink = async ({
	queryParameters,
	deepLinkData,
	language,
	storefrontUrl,
}: {
	queryParameters: URLSearchParams
	deepLinkData: any
	storefrontUrl: string
	language: LanguageCode
}) => {
	const path = getRedirectionPath(deepLinkData?.destination?.type, language)
	const attachment = deepLinkData?.attachment
	const destinationStoreId = deepLinkData?.destination?.storeId

	generateUtmQueryParameters(deepLinkData?.utm)?.forEach((value, key) => queryParameters.set(key.toString(), value))

	// open selected store page
	if (destinationStoreId && storefrontUrl) {
		try {
			const response = await axios.get(`${storefrontUrl}/v1/stores/${destinationStoreId}?pick=seo`)
			const normalizeFileNameStr = await getNormalizedStoreName(response?.data?.title, language)

			return `${path}${normalizeFileNameStr ? `/${normalizeFileNameStr}` : ''}${queryParameters.toString()}`
		} catch (e: any) {
			console.error(e?.message || e.toString())
		}
	}

	// force localization
	const couponOrItemCode = attachment?.couponCode || attachment?.itemCoupon
	if (deepLinkData?.storeId) {
		queryParameters.set('forceLocalization', 'true')
		queryParameters.set('storeId', deepLinkData?.storeId)

		if (couponOrItemCode) {
			queryParameters.set('couponCode', couponOrItemCode)
		}
		return `${path}?${queryParameters.toString()}`
	}

	const pathWithQueryParameters = `${path}?${queryParameters.toString()}`
	if (couponOrItemCode) {
		return `${pathWithQueryParameters}#cc_${couponOrItemCode}`
	}

	return pathWithQueryParameters
}

export const fetchDeepLink = async (deepLinkId: string, chainId: string): Promise<DeepLinkData | undefined> => {
	try {
		return (await sendRequest(
			false,
			`${getECommerceDomainByEnv()}/v1/tenants/${chainId}/deepLinks/${deepLinkId}`,
			'get',
			{
				'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
			},
			undefined,
			undefined,
			undefined,
			undefined,
			false
		)) as DeepLinkData | undefined
	} catch (error) {
		console.error(error)
	}
}

export const modifyQueryParameters = (queryParameters: URLSearchParams, deepLinkId: string) => {
	const defaultRefQueryParameterValue = JSON.stringify({ deepLinkId })

	if (!queryParameters) {
		console.error('Invalid query parameters')
		queryParameters = new URLSearchParams({ ref: defaultRefQueryParameterValue })

		return queryParameters
	}

	const ref = queryParameters.get('ref')

	if (!ref) {
		queryParameters.set('ref', defaultRefQueryParameterValue)
	} else {
		try {
			const refObject = JSON.parse(ref)
			const refModifiedObject = { ...refObject, deepLinkId }

			queryParameters.set('ref', JSON.stringify(refModifiedObject))
		} catch (e) {
			console.error(e)

			queryParameters.set('ref', defaultRefQueryParameterValue)
		}
	}

	return queryParameters
}

const filterUtmParameters = (queryString: string): string => {
	const params = new URLSearchParams(queryString)
	const filteredParams = new URLSearchParams()

	for (const [key, value] of params.entries()) {
		if (key.startsWith('utm_')) {
			filteredParams.append(key, value)
		}
	}

	return filteredParams.toString()
}

export const openDeepLink = async (deepLink: string, Infra: typeof _Infra, router: NextRouter) => {
	const safeDeepLink = deepLink || '/home'
	const [safeDeepLinkWithoutQuery, queryParameters] = safeDeepLink.split('?')
	const modifiedQueryParameters = new URLSearchParams(queryParameters)
	const refEncodedData = modifiedQueryParameters?.get('ref')
	const utmParametersQuery = filterUtmParameters(queryParameters)

	Infra.setLoading(true)
	try {
		Infra.setAppParams({ ...Infra.appParams, ref: encodeURIComponent(refEncodedData || '') })
	} catch (e) {
		console.error(e)
	}

	await router.replace(safeDeepLink)
	// Remove the query parameters from the URL right after they were used by the system. So when navigating back to the page via the browser's back button, the query parameters won't be there
	router.replace(`${safeDeepLinkWithoutQuery}${utmParametersQuery ? `?${utmParametersQuery}` : ''}`, undefined, { shallow: true })
	Infra.setLoading(false)
}
