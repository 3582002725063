// @ts-nocheck
import React from 'react'
import { ORDER_TYPES } from 'utils/constants'
import { getTranslatedTextByKey, isMobileApp } from 'utils/utils'
import SmartLink from 'static/components/SmartLink'
import Card from 'static/components/StoreLocator/common/Card'
import Divider from '@material-ui/core/Divider'
import styled from 'styled-components/macro'
import { sendCustomEvent } from 'utils/analytics/analytics'
import { useRouter } from 'next/router'
import type _User from 'mobx/User'
import type _Infra from 'mobx/Infra'
import type _AddressManager from 'mobx/AddressManager'

const ListItem = styled.div`
	margin-bottom: 10px;
	padding: 0 10px;

	@media (max-width: 768px) {
		margin: 0;
		padding: 0;
	}
`

const StyledCard = styled(Card)`
	padding: 15px 20px;
`

const StyledDivider = styled(Divider)`
	position: relative;
	z-index: 999;
	height: 1px;
	background-color: var(--disable);
`

interface StoreListRowProps {
	index: number
	storesLists: any[]
	listData: any
	User: typeof _User
	Infra: typeof _Infra
	AddressManager: typeof _AddressManager
	onSelectStore: any
	locale: any
	selectedOrderType: any
	selectedStoreOnMap: any
	innerRef: any
	setSelectedOrderType: any
}

const StoreListRow = ({
	index,
	storesLists,
	listData,
	User,
	Infra,
	AddressManager,
	onSelectStore,
	locale,
	selectedOrderType,
	selectedStoreOnMap,
	innerRef,
	setSelectedOrderType,
}: StoreListRowProps) => {
	const store = storesLists[index]
	const { rank = index + 1, availability = {} } = store

	const router = useRouter()

	const { open, orderOnline, preOrder, supportedOrderTypes } = availability

	const buttonPropsDependOnConf = {}

	if ((open && orderOnline) || preOrder) {
		// regular order
		buttonPropsDependOnConf.onClick = () => {
			if (listData.isStoreOutOfDeliveryPickupArea) {
				if (AddressManager.addressPopup?.mapButtonClicked) {
					onSelectStore(store.id)
					return
				}

				const buttons = {
					...(supportedOrderTypes.includes(ORDER_TYPES.PICKUP) && {
						cancelAction: () => {
							Infra.closeNotification()
							onSelectStore(store.id, ORDER_TYPES.PICKUP)
						},
					}),
					...(supportedOrderTypes.includes(ORDER_TYPES.DELIVERY) && {
						okAction: () => {
							selectedStoreOnMap(store)
							if (supportedOrderTypes.includes(ORDER_TYPES.DELIVERY)) {
								setSelectedOrderType(ORDER_TYPES.DELIVERY)
							}
							Infra.closeNotification()
						},
					}),
				}

				Infra.setNotification({
					open: true,
					title: getTranslatedTextByKey('eCommerce.storeLocator.howWouldYouLikeToOrder', 'How would you like to receive Your order?'),
					message: getTranslatedTextByKey(
						'eCommerce.storeLocator.menuAvailabilityMayVary',
						'Menu availability and pricing may vary between order types'
					),
					cancelText: getTranslatedTextByKey('webviewFlow.pickupOrder', 'pick up'),
					okText: getTranslatedTextByKey('webviewFlow.deliveryOrder', 'delivery'),
					okButtonType: 'secondary',
					closeNotification: () => Infra.closeNotification(),
					...buttons,
				})
			} else {
				sendCustomEvent({
					category: 'address box',
					action: `clicked on ${preOrder ? 'pre ' : ''}start my order`,
					label: store.id,
					orderType: selectedOrderType,
					page_location: window.location.href?.split('?')[0],
				})
				onSelectStore(store.id)
			}
		}
		buttonPropsDependOnConf.id = `localization-page-start-order-button-${rank}`
		buttonPropsDependOnConf['data-testid'] = 'start-my-order-button'
	} else if (!orderOnline) {
		// static menu
		buttonPropsDependOnConf.onClick = () => {
			AddressManager.closeAddressPopup()
			return SmartLink.route(`/${User.preferredLanguage}/menu`, router, !isMobileApp())
		}
		buttonPropsDependOnConf['data-testid'] = 'browse-static-menu-button'
	}

	return (
		<ListItem ref={innerRef}>
			<StyledCard
				data={store}
				buttonProps={{ ...buttonPropsDependOnConf }}
				rank={rank}
				hasStores={!!availability?.[selectedOrderType]?.availableForAddress}
				locale={locale.msg}
				elementType="div"
			/>
			{storesLists.length - 1 !== index && <StyledDivider />}
		</ListItem>
	)
}

export default StoreListRow
