// @ts-nocheck
import React, { useState, useContext } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { inject, observer } from 'mobx-react'
import { Grid } from '@material-ui/core'
import classnames from 'classnames'
import {
	isIPhoneX,
	formatPrice,
	isIphone,
	checkMinimumOrder,
	getLocaleStr,
	getSectionTitleForItem,
	getTranslatedTextByKey,
	getStoreName,
	getSectionIdForItem,
	codeToLocale,
	isMobile,
	routeToPage,
} from 'utils/utils'
import type { RouteComponentProps } from 'react-router-dom'
import { finish } from 'utils/chatAppUtils'
import queryString from 'query-string'
import { StoreContext } from 'contexts/StoreContext'
import ShareWithFriendForm from '../ShareWithFriendForm'
import { Textfit } from 'react-textfit'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { CONSTANTS, ORDER_TYPES, Page } from 'utils/constants'
import { sendEnhancedEcommerceEvent, sendCustomEvent, getRestaurantAnalyticsFields } from 'utils/analytics/analytics'
import ButtonBase from 'components/common/ButtonBase'
import type { Theme } from 'utils/theme/GlobalStyle'
import type _User from 'mobx/User'
import type _Infra from 'mobx/Infra'
import type _Payment from 'mobx/Payment'
import type _Application from 'mobx/Application'
import type _ItemAdditions from 'mobx/ItemAdditions'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'
import { useStores } from 'hooks/useStores'
import { useRouter } from 'next/router'
import { SnackbarStatus } from 'mobx/Infra/Infra.type'

const EEE = CONSTANTS.ANALYTICS.ENHANCED_ECOMMERCE_EVENTS

const AddItemButton = styled(ButtonBase)<{ $size?: string; $type?: string }>`
	margin: 8px 6px;
	width: 95%;
	font-size: ${({ theme }) => theme.typography.CTASmall.fontSize} !important;
`

const AddItemContainer = styled.div`
	display: block;
`

const Container = styled.div`
	&.appBar {
		position: absolute;
		bottom: 0;
		&.itemHasNoVariations {
			width: 100%;
		}

		@media (max-width: 576px) {
			height: 70px;
		}
		box-shadow: lightgrey 0px -2px 4px;
		background-color: #ffffff;
		backdrop-filter: blur(2px);
		width: 100%;
		touch-action: none;

		@media (min-width: 1280px) {
			width: calc(100% - 441px);
		}

		@media (max-width: 576px) {
			.buttonsContainer {
				height: 90%;
			}

			.buttonContainer {
				height: 100%;
			}
		}
	}
	&.subtitle1 {
		line-height: 17px;
		font-weight: 500;
		font-size: 18px;
		@media (max-width: 576px) {
			font-size: 15px;
		}
		@media (min-width: 1280px) {
			font-size: 16px;
			margin-bottom: 4px;
		}
	}
	&.caption {
		line-height: 16px;
		font-size: 10px;
		@media (max-width: 576px) {
			font-size: 12px;
		}
		@media (min-width: 1280px) {
			font-size: 16px;
		}
	}
	&.submitButtons {
		padding: 4px 4px 1px 4px;
		margin: 11px 18px;
		width: 90%;
		height: 53px;

		@media (max-width: 1279px) and (min-width: 577px) {
			margin: 8px 6px;
			width: 90%;
		}

		@media (max-width: 576px) {
			margin: 8px 6px;
			width: 94%;
		}
		background-color: white;
		border-width: 1px;
		border-radius: 5px;

		&:hover {
			// you want this to be the same as the backgroundColor above
			background-color: white;
		}
	}
	&.placeOrder {
		color: white;
	}
	&.buttonLabel {
		display: block;
		@media (min-width: 1280px) {
			width: 100%;
			padding: 0 10px;
		}
	}
	&.item {
		text-align: center;
	}
	&.iphoneX {
		height: 80px;
		padding-bottom: 20px;
	}
`

interface MenuItemPageFooterProps {
	User: typeof _User
	ItemAdditions: typeof _ItemAdditions
	Infra: typeof _Infra
	Payment: typeof _Payment
	Application: typeof _Application
	currency: string
	itemHasNoVariations?: boolean
}

const MenuItemPageFooter = inject(
	'User',
	'ItemAdditions',
	'Infra',
	'Payment',
	'Application',
	'Home'
)(
	observer(
		({
			Application,
			ItemAdditions,
			User,
			Infra,
			Home,
			Payment,
			currency,
			itemHasNoVariations,
		}: MenuItemPageFooterProps & RouteComponentProps) => {
			const router = useRouter()
			const parsedQueryString = queryString.parse(window.location.search)
			const { store } = useContext(StoreContext)
			const { cartStore } = useStores()
			const theme = useTheme<Theme>()

			const rest = store.data
			const isMobileView = !useMediaQuery({ query: '(min-width: 576px)' })
			const maxFontSizeAsString = theme?.typography?.CTABig?.fontSize?.split('px')[0]
			const isMenu = ['/menu', '/summary'].includes(window.location.pathname)
			const orderType = User.getOrderType()

			const item = ItemAdditions.getItem()
			let maxFontSize = 20
			const eCommerce = Infra.appParams?.eCommerce
			let { showPlaceOrderButtonOnMenuItemPopup } = Infra.appParams

			// if the flag is missing, the default is true to show both buttons
			showPlaceOrderButtonOnMenuItemPopup = showPlaceOrderButtonOnMenuItemPopup === undefined ? true : showPlaceOrderButtonOnMenuItemPopup
			const showPlaceOrderButton = !eCommerce ? true : showPlaceOrderButtonOnMenuItemPopup

			try {
				maxFontSize = Number(maxFontSizeAsString)
			} catch (e) {
				console.error(e)
			}

			const [state, setState] = useState({
				buttonDisabled: false,
				hideFooter: false,
			})

			const handleScrollForRequiredItems = (_allRequiredSelectionsProvided: { validIds: string[]; invalidIds: string[] }) => {
				if (_allRequiredSelectionsProvided.invalidIds.length) {
					let lowestOffset: number | null = null
					let doScroll = false

					_allRequiredSelectionsProvided.invalidIds.forEach((invalidId) => {
						const _variation = document.getElementById(invalidId)

						if (_variation) {
							const _parentHtmlElemById = _variation.closest('.required')

							// if the parent is hidden then _parentHtmlElemById will be undefined so we use the visible DOM element (_variation)
							const _elementToUpdateCSS = _parentHtmlElemById ?? _variation

							if (_elementToUpdateCSS) {
								doScroll = true
								_elementToUpdateCSS.classList.add('invalid')
								if (_elementToUpdateCSS.offsetTop < lowestOffset || lowestOffset === null) {
									lowestOffset = _elementToUpdateCSS.offsetTop
								}
							}
						} else {
							console.info(
								`variation with id: '${invalidId}' does NOT exist in the DOM so may have been removed due to it belonging to a different parent variation that has been UNSELECTED`
							)
						}
					})

					if (doScroll) {
						const _showDesktopView = window.innerWidth >= 1280
						const scrollingHtmlElementId = _showDesktopView ? 'menuItemPageSections' : 'menuItemOverlayContent'
						if (isMobileView) {
							const mobileNavBarHeight = (document.querySelector('#appBarHeader') as HTMLElement)?.offsetHeight
							lowestOffset -= mobileNavBarHeight
						}

						// 2. scroll to the inner div's id
						/**
						 * the bug is when we click "placeOrder" button, its should scroll to the required variation
						 * when I debug it and add breakpoint immediately after the scroll it worked!
						 * but if I don’t debug it, so It's still not working,
						 * so I add this setTimeout combination because the callback will get into callback queue, and then it will be called after the time is finished
						 * delay it's not necessary here.
						 * */
						setTimeout(() => {
							document.getElementById(scrollingHtmlElementId)?.scroll({
								behavior: 'smooth',
								top: lowestOffset,
							})
						}, 0)
					}
				}
			}

			const _addItemToCart = async () => {
				const _allRequiredSelectionsProvided = ItemAdditions.validateAllRequiredSelectionsProvided

				// remove invalid styling from any relevant sections
				_allRequiredSelectionsProvided.validIds
					.map((id) => document.getElementById(id))
					.filter(Boolean)
					.forEach((element) => element.classList.remove('invalid'))

				if (!_allRequiredSelectionsProvided.invalidIds.length) {
					const newItem = JSON.parse(JSON.stringify(ItemAdditions.getItem()))
					const _fullItem = rest.items[newItem.itemId]

					if (_fullItem.description.oneTime && cartStore.items[newItem.itemId]) {
						// defense against a customer adding a one-time item more than once
						Infra.showSnackbar({
							snackId: 'cart',
							message: getTranslatedTextByKey('webviewFlow.itemIsOneTime', `This item can only be added once to your cart`),
							status: SnackbarStatus.WARN,
							isAttachedToElement: true,
						})

						cartStore.closeItemModal()

						sendCustomEvent({
							category: 'menu',
							action: 'add to cart',
							label: `not allowed to add item with id ${newItem.itemId} a 2nd time`,
						})

						return false
					}

					cartStore.closeItemModal()

					Application.dependencies?.replaceHistoryState()

					const success = await cartStore.addItem(newItem)

					if (!success) {
						return false
					}

					const locale = User.preferredLanguage ? codeToLocale[User.preferredLanguage] : rest.locale
					const itemTitle = getLocaleStr(_fullItem.title, locale)
					Infra.showSnackbar({
						snackId: isMobile() ? 'checkout-btn' : 'cart',
						message: `'${itemTitle}' ${getTranslatedTextByKey('wasAddedToCart')}`,
						status: SnackbarStatus.SUCCESS,
					})

					const sectionId = getSectionIdForItem(_fullItem.id, rest!.sections)
					const section = rest.sections.find((_section) => _section.id === sectionId)
					const eventMetaData = {
						itemTitle,
						sectionTitle: getLocaleStr(section?.title, locale),
						currency: rest.currency,
						countryCode: rest.countryCode,
						category_id: sectionId,
						storeName: getStoreName(store, orderType, Home.locale.msg),
					}

					sendEnhancedEcommerceEvent(EEE.sendAddToCartEvent, newItem, eventMetaData, cartStore.getAnalyticsFields())
				}

				handleScrollForRequiredItems(_allRequiredSelectionsProvided)
				clearAllBodyScrollLocks()

				// return true if there are no invalid ids
				return !_allRequiredSelectionsProvided.invalidIds.length
			}

			const updateItemInCard = async () => {
				const _allRequiredSelectionsProvided = ItemAdditions.validateAllRequiredSelectionsProvided

				// If invalid selection provided, we scroll to it and stop the code execution
				if (_allRequiredSelectionsProvided.invalidIds.length) {
					handleScrollForRequiredItems(_allRequiredSelectionsProvided)
					return false
				}

				const newItem = JSON.parse(JSON.stringify(ItemAdditions.getItem()))
				await cartStore.updateItem(cartStore.modalItemIndex, newItem)

				const title = getLocaleStr(rest.items[newItem.itemId].title, rest.locale)
				Infra.showSnackbar({
					snackId: 'cart',
					message: `'${title}' ${getTranslatedTextByKey('webviewFlow.wasUpdated')}`,
					status: SnackbarStatus.SUCCESS,
					isAttachedToElement: isMenu,
				})
				cartStore.closeItemModal()

				// Application.dependencies?.replaceHistoryState()

				// NB overlay opens on top of /menu or /check-out with a #menu-item added to the end of the url
				// props.history.goBack()

				const eventMetaData = {
					itemTitle: title,
					description: rest.items[newItem.itemId].description,
					sectionTitle: getSectionTitleForItem(newItem.itemId, rest.sections, rest.locale),
					currency: rest.currency,
					countryCode: rest.countryCode,
					storeName: getStoreName(store, orderType, Home.locale.msg),
				}
				// UPDATE event is same as ADD event (according to our GTM expert)
				sendEnhancedEcommerceEvent(EEE.sendAddToCartEvent, newItem, eventMetaData, cartStore.getAnalyticsFields())
			}

			const placeOrder = async () => {
				// we disable the button since we don't close the popup for Messenger/WA after the user has clicked it so
				// the user can click it again while the /webview API is called.
				//
				// ALSO on WA on iPhone window.close() does nothing! so we remove the footer to prevent the user from being able to do anything
				setState({
					buttonDisabled: true,
					hideFooter: isIphone() && User.session.appid === 1,
				})

				const _allRequiredSelectionsProvided = await _addItemToCart()
				if (!_allRequiredSelectionsProvided) {
					setState({
						buttonDisabled: false,
						hideFooter: false,
					})
					return
				}

				const submitHandler = (formData: any) => {
					Infra.closeNotification()
					finish(null, cartStore.items, rest.items, true, formData.name)
				}

				sendCustomEvent({
					category: 'checkout',
					action: 'button pressed',
					label: 'place order',
					currency,
					price: formatPrice(parseInt(cartStore.grandTotal, 10) + parseInt(ItemAdditions.totalCost, 10), '', '', 1, false),
				})

				const deliveryType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PICKUP
				sendEnhancedEcommerceEvent(
					EEE.sendCheckOutEvent,
					getRestaurantAnalyticsFields(rest),
					{
						step: CONSTANTS.CHECKOUT_STEPS.GOTO_CHECKOUT_PAGE,
						deliveryType,
						paymentType: Payment.paymentMethod,
						storeID: store?.data?.id || '',
						storeName: getStoreName(store) || '',
					},
					cartStore.getAnalyticsFields()
				)

				if (
					[CONSTANTS.APP.TYPES.WEB, CONSTANTS.APP.TYPES.WEB_MOBILE, CONSTANTS.APP.TYPES.ANDROID_APP, CONSTANTS.APP.TYPES.IOS_APP].includes(
						User.session.appid
					)
				) {
					setTimeout(async () => {
						// give time for the url to change back from /menu/item/XXX to /menu before changing to /checkout
						// const setItemsResponse = await setItemsAPI(cartStore.items, rest)
						const parsed = queryString.parse(window.location.search)
						const setItemsResponse = await cartStore.saveCart({
							btnClicked: typeof parsed.btnClicked === 'string' ? parsed.btnClicked : undefined,
							ais: typeof parsed.ais === 'string' ? parsed.ais : undefined,
						})

						if (!setItemsResponse) {
							return
						}

						router.push(`/checkout${window.location.search}`)
					}, 100)
				} else {
					// for chat apps check min order (for web - setItems also checks min order
					const res: any = await checkMinimumOrder()

					if (res.error) {
						Infra.setErrorNotification(res.msg, true, true)
						return
					}

					if (
						(parsedQueryString.mode && parsedQueryString.mode === 'editSingle') ||
						(parsedQueryString.ais && parsedQueryString.ais === 'expressCheckout')
					) {
						cartStore.closeMenuItem()
					} else if (parsedQueryString.shared) {
						cartStore.closeItemModal()

						setTimeout(() => {
							// give 300ms time for the menu-item to close before showing this popup
							Infra.setNotification({
								// open: true,
								title: '',
								message: <ShareWithFriendForm closeForm={submitHandler} inviter={parsedQueryString.firstName} />,
							})
						}, 300)
					} else {
						// close and redirect to chat app
						finish(null, cartStore.items, rest.items, true)
					}
				}
			}

			const cancel = () => {
				// the 'cancel' button is only shown for express checkout or editing a single item. Clicking this button closes the menu and returns to the chat app
				finish(true, undefined, undefined)
			}

			if (state.hideFooter) {
				return null
			}

			return (
				<Container
					className={classnames(
						'menuItemPageFooter appBar',
						isIPhoneX() ? 'iphoneX' : ``,
						itemHasNoVariations ? 'itemHasNoVariations' : ''
					)}
				>
					<Grid container alignItems="center" justifyContent="center" direction="row" spacing={0} className="buttonsContainer">
						{!cartStore.itemModalEditMode &&
							!!parsedQueryString.mode === false &&
							!!parsedQueryString.ais === false &&
							showPlaceOrderButton &&
							(!rest?.items[item.itemId]?.isUpsell || routeToPage(window.location.pathname) !== Page.CHECKOUT) && (
								<Grid item xs={6} className="menuItemPageFooter item buttonContainer">
									<AddItemButton
										$type="secondary"
										$size="big"
										onClick={
											(parsedQueryString.mode && parsedQueryString.mode === 'editSingle') ||
											(parsedQueryString.ais && parsedQueryString.ais === 'expressCheckout')
												? cancel
												: placeOrder
										}
										disabled={state.buttonDisabled || cartStore.loading}
										id={`${Application.page}-page-place-order`}
										data-testid="place-order-btn"
									>
										<AddItemContainer>
											<Textfit mode="single" forceSingleModeWidth={false} max={maxFontSize}>
												{[
													CONSTANTS.APP.TYPES.WEB,
													CONSTANTS.APP.TYPES.WEB_MOBILE,
													CONSTANTS.APP.TYPES.ANDROID_APP,
													CONSTANTS.APP.TYPES.IOS_APP,
												].includes(User.session.appid)
													? getTranslatedTextByKey('webviewFlow.placeOrder')
													: (parsedQueryString.mode && parsedQueryString.mode === 'editSingle') ||
													  (parsedQueryString.ais && parsedQueryString.ais === 'expressCheckout')
													? getTranslatedTextByKey('cancel')
													: getTranslatedTextByKey('btns.nextStage')}
											</Textfit>
											<span data-testid="menu-item-dialog-place-order-button-price">
												{!parsedQueryString.mode &&
													formatPrice(
														parseInt(cartStore.grandTotal) + parseInt(ItemAdditions.totalCost),
														currency,
														rest.countryCode
													)}
											</span>
										</AddItemContainer>
									</AddItemButton>
								</Grid>
							)}

						<Grid item xs={showPlaceOrderButton ? 6 : 12} className="menuItemPageFooter item buttonContainer">
							<AddItemButton
								id={`${Application.page}-page-add-to-cart`}
								$size="big"
								onClick={
									cartStore.itemModalEditMode
										? updateItemInCard
										: (parsedQueryString.mode && parsedQueryString.mode === 'editSingle') ||
										  (parsedQueryString.ais && parsedQueryString.ais === 'expressCheckout')
										? placeOrder
										: () => _addItemToCart()
								}
								disabled={cartStore.loading}
								data-testid="add-to-cart-btn"
								fullWidth
							>
								<AddItemContainer>
									<Textfit mode="single" forceSingleModeWidth={false} max={maxFontSize}>
										{cartStore.itemModalEditMode
											? getTranslatedTextByKey('webviewFlow.upateCart')
											: getTranslatedTextByKey('addToCart')}
									</Textfit>
									<div data-testid="menu-item-dialog-add-to-cart-button-price">
										{formatPrice(ItemAdditions.totalCost, currency, rest.countryCode)}
									</div>
								</AddItemContainer>
							</AddItemButton>
						</Grid>
					</Grid>
				</Container>
			)
		}
	)
)

export default MenuItemPageFooter
