import type { CheckCouponOn } from 'mobx/CouponFlow'
import { OrderType } from 'shared-types/Coupon'
import type { Coupon, IndividualCoupon, InStoreCoupon, WeeklyAvailability } from 'shared-types/Coupon'
import type { LanguageLocale } from 'utils/language'
import type { ShowSnackbarProps } from 'mobx/Infra/Infra.type'

enum ExtraFilters {
	ALL = 'all',
	MY_COUPONS = 'myCoupons',
}

export const CouponFilter = { ...ExtraFilters, ...OrderType } as const
export type Filter = ExtraFilters | OrderType

// Server-Side Stuff
export interface SearchBody {
	fields: {
		name: string
		operator: 'eq' | 'gr' | 'lt' | 'in'
		value: string | string[] | number | number[]
		logicalOperator?: 'and' | 'or'
		pagination?: { offset: number; limit: number }
		sort?: { fieldName: string; direction: 'ASC' | 'DESC' }
	}[]
}

export interface PrbResponse {
	couponCode: string
	couponUrl: string | null
}

export interface PrbCoupon {
	code: string
	url: string
}

// Due to a TypeScript limitation, this has to be a type and not an interface
// Read more: https://www.typescriptlang.org/docs/handbook/2/everyday-types.html#differences-between-type-aliases-and-interfaces
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type GetCouponQuery = {
	storeId?: string
}

export interface Feature {
	name: 'displayInCouponWallet' | 'requireLoginWallet'
	[key: string]: string
}

export interface DateTimeObject {
	year: number
	month: number
	day: number
	hour: number
	minute: number
}

interface Exception {
	comment: Record<LanguageLocale, string>
	start: DateTimeObject
	end: DateTimeObject
	available: boolean
}

export interface ServerSideCoupon extends Omit<Coupon, 'flags' | 'orderTypes' | 'timestamp' | 'expiration' | 'availability'> {
	expiration: number
	features: Feature[]
	orderTypes: (OrderType | 'peakup' | 'curbside' | 'tableside' | 'dineIn' | 'takeAway')[]
	availability: {
		weekly: WeeklyAvailability[]
		exceptions: Exception[]
	}
}

export type AddCouponToWalletResponse = Pick<IndividualCoupon, 'id' | 'code' | 'parentCode' | 'status' | 'totalUses' | 'usesLeft'> &
	Pick<ServerSideCoupon, 'expiration'> & {
		metadata: Pick<ServerSideCoupon, 'title' | 'description'>
	}

export interface CouponsDependencies {
	getInternalCoupons(): Promise<Exclude<Coupon, InStoreCoupon>[]>
	getExternalCoupons(): Promise<InStoreCoupon[]>
	getCoupon(code: string, checkCouponOn: CheckCouponOn): Promise<Coupon | null>
	addCouponToWallet(code: string): Promise<AddCouponToWalletResponse>
	addCouponToWalletBulk(codes: string[]): Promise<AddCouponToWalletResponse[]>
	getPrbCode(id: string): Promise<PrbCoupon>
	getIgnoreOrderTypes(): OrderType[]
	isLocalized(): boolean
	showSnackbar: (data: ShowSnackbarProps) => void
}
