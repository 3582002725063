import { action, computed, observable } from 'mobx'
import type { CartAnalyticsFields, CartDependencies, SaveCartPayload } from './types'
import type { DiscountCartChange } from 'shared-types/Cart'
import { type CartItem, type Cart, type DeliveryInfo, type ServerCharge, type CartChange, CartChangeType } from 'shared-types/Cart'
import { DiscountInactiveReasonCode, OfferType, type Coupon } from 'shared-types/Coupon'
import type MenuItem from 'types/MenuItem'
import { isEmpty, uniq } from 'lodash-es'
import type MenuSection from 'types/MenuSection'
import type MenuUpsells from 'types/MenuUpsells'
import { SnackbarStatus } from 'mobx/Infra/Infra.type'
import { getLocaleStr, getTranslatedTextByKey } from 'utils/language'
import { sendCustomEvent } from 'utils/analytics/analytics'
import type { OrderEntity } from 'types/OrderEntity'
import { transformItem } from './utils'

export default class CartStore {
	private readonly dependencies: CartDependencies

	@observable storeId = ''

	@observable items: Record<string, CartItem[]> = {}

	@observable charges: ServerCharge[] = []

	@observable addedItemsFromDiscounts: any[] = []

	@observable grandTotal = 0

	@observable serverDisableCouponsField = false

	@observable serverDisableSpecialDiscounts = false

	@observable serverWarningsToDisplay: string[] = []

	@observable serverSpecialDiscountsIdLimit = 0

	@observable deliveryInfo: DeliveryInfo = {
		minOrderPrice: 0,
		type: '',
		delayMins: 0,
		charge: 0,
		inactive: true,
	}

	// TODO-Jacob: move to menu store (create a new one to be populated after menu refactor)
	@observable itemModalOpen = false

	@observable modalItemId = ''

	@observable modalItemIndex = 0

	@observable itemModalEditMode = false

	@observable ready = false

	@observable loading = false

	constructor(dependencies: CartDependencies) {
		this.dependencies = dependencies
	}

	getAnalyticsFields(): CartAnalyticsFields {
		return {
			items: this.items,
			grandTotal: this.grandTotal,
			charges: this.charges,
			coupons: this.getAppliedCoupons(),
			// legacy field
			serverCharges: this.charges,
			// legacy field
			serverGrandTotal: this.grandTotal,
		}
	}

	@computed get numberOfItems(): number {
		return Object.values(this.items).reduce((acc, items) => acc + items.length, 0)
	}

	getAppliedCoupons(): Coupon[] {
		return this.dependencies.getCartDiscounts()
	}

	getAppliedCouponsWithoutGovernment(): Coupon[] {
		return this.dependencies.getCartDiscountsWithoutGovernment()
	}

	@action
	setReady(ready: boolean): void {
		this.ready = ready
	}

	@action
	setLoading(loading: boolean): void {
		this.loading = loading
	}

	@action getUpsellInTheCart(menuItems: Record<string, MenuItem>): MenuItem | null {
		const item = Object.values(this.items)
			.flat()
			.find(({ itemId }) => !!menuItems[itemId]?.isUpsell)
		if (item) {
			return menuItems[item.itemId]
		}
		return null
	}

	@action
	openItemModal(item: CartItem, index = -1): void {
		this.itemModalOpen = true
		this.modalItemId = item.itemId
		this.itemModalEditMode = false
		if (index !== -1) {
			this.itemModalEditMode = true
			this.modalItemIndex = index

			item = this.items[item.itemId][index]
		}

		this.dependencies.postInit(item, this.itemModalEditMode)
	}

	@action
	closeItemModal(): void {
		this.itemModalOpen = false
		this.modalItemId = ''
		this.modalItemIndex = 0
		this.itemModalEditMode = false
	}

	@action
	async setCart(cart: Cart | null): Promise<void> {
		if (!cart) {
			return
		}
		this.items = cart.items
		await this.dependencies.setCartDiscounts(cart.discounts)
		this.charges = cart.charges
		this.grandTotal = cart.grandTotal
		this.serverDisableCouponsField = cart.serverDisableCouponsField
		this.serverDisableSpecialDiscounts = cart.serverDisableSpecialDiscounts
		this.serverWarningsToDisplay = cart.serverWarningsToDisplay
		this.serverSpecialDiscountsIdLimit = cart.serverSpecialDiscountsIdLimit
		this.addedItemsFromDiscounts = cart.addedItemsFromDiscounts
		this.deliveryInfo = cart.deliveryInfo
		this.handleChanges(cart.changes)
	}

	handleChanges(changes: CartChange[]): void {
		for (const change of changes) {
			const { type } = change
			switch (type) {
				case CartChangeType.ADD_ITEM:
					// nothing to do here
					break
				case CartChangeType.REMOVE_ITEM:
					// nothing to do here
					break
				case CartChangeType.UPDATE_ITEM:
					// nothing to do here
					break
				case CartChangeType.REMOVE_ITEMS:
					this.dependencies.showSnackbar({
						snackId: '',
						message: change.errorMessage,
						status: SnackbarStatus.WARN,
					})
					break
				case CartChangeType.ADD_DISCOUNT:
				case CartChangeType.REMOVE_DISCOUNT:
				case CartChangeType.DISCOUNT_ACTIVATED:
				case CartChangeType.DISCOUNT_DEACTIVATED:
					this.handleDiscountsChanges(change)
					break
				case CartChangeType.OPEN_ITEM_MODAL:
					this.openItemModal(change.item)
					break
				default:
					break
			}
		}
	}

	handleDiscountsChanges(change: DiscountCartChange): void {
		const { type, code } = change
		const coupon = this.dependencies.getCoupons().find((c) => c.code === code)
		const locale = this.dependencies.getUserLocale()
		if (!coupon) {
			return
		}

		switch (type) {
			case CartChangeType.ADD_DISCOUNT:
				sendCustomEvent({
					category: coupon.offerType === OfferType.ITEM ? 'coupon' : 'discount',
					action: 'redeem success',
					label: change.code,
				})
				this.dependencies.showSnackbar({
					snackId: '',
					message: `'${getLocaleStr(coupon.title, locale)}' ${getTranslatedTextByKey('wasAddedToCart', 'was added to your cart')}`,
					status: SnackbarStatus.SUCCESS,
				})
				break
			case CartChangeType.REMOVE_DISCOUNT:
				sendCustomEvent({
					category: 'discount',
					action: 'remove success',
					label: change.code,
				})
				this.dependencies.showSnackbar({
					snackId: '',
					message: `'${getLocaleStr(coupon.title, locale)}' ${getTranslatedTextByKey(
						'webviewFlow.removedFromYourCart',
						'Removed from your cart'
					)}`,
					status: SnackbarStatus.SUCCESS,
				})
				break
			case CartChangeType.DISCOUNT_ACTIVATED:
				this.dependencies.showSnackbar({
					snackId: '',
					message: `${getLocaleStr(coupon.title, locale)} ${getTranslatedTextByKey('webviewFlow.discountApplied', 'Discount applied!')}`,
					status: SnackbarStatus.SUCCESS,
				})
				break
			case CartChangeType.DISCOUNT_DEACTIVATED:
				this.handleDiscountDeactivated(coupon)
				break
			default:
				break
		}
	}

	handleDiscountDeactivated(coupon: Coupon): void {
		const locale = this.dependencies.getUserLocale()
		if (!coupon.flags.active?.reasonCode || !coupon.flags.active?.value) {
			return
		}

		const { reasonCode } = coupon.flags.active
		switch (reasonCode) {
			case DiscountInactiveReasonCode.MIN_ORDER_PRICE_NOT_MET:
				if (coupon.minOrderPrice) {
					this.dependencies.showSnackbar({
						snackId: '',
						message: `${getTranslatedTextByKey('webviewFlow.coupons.spendAnother', 'Spend another')} ${
							coupon.minOrderPrice! - this.grandTotal
						} ${getTranslatedTextByKey('webviewFlow.coupons.toActive', 'to activate')} ${getLocaleStr(
							coupon.title,
							locale
						)} ${getTranslatedTextByKey('webviewFlow.coupons.coupon', 'coupon')}`,
						status: SnackbarStatus.WARN,
					})
				}
				break
			case DiscountInactiveReasonCode.MISSING_SPECIFIC_ITEMS:
				// TODO-Jacob: Implement proper message
				break
			case DiscountInactiveReasonCode.MISSING_ITEMS_FROM_SECTION:
				// TODO-Jacob: Implement proper message
				break
			default:
				break
		}
	}

	async saveCart(payload: SaveCartPayload): Promise<boolean> {
		this.setLoading(true)
		const result = await this.dependencies.saveCart(payload)
		console.log('saveCart result', result)
		this.setLoading(false)
		this.setReady(true)
		return result.success
	}

	@action
	async fetchCart(): Promise<void> {
		if (this.loading) {
			return
		}

		this.setLoading(true)
		const newCart = await this.dependencies.fetchCart()
		console.log('getCart newCart', newCart)
		await this.setCart(newCart)
		this.setLoading(false)
		this.setReady(true)
	}

	@action
	async resetCart(): Promise<void> {
		this.setLoading(true)
		const newCart = await this.dependencies.resetCart()
		console.log('resetCart newCart', newCart)
		await this.setCart(newCart)
		this.setLoading(false)
	}

	@action
	async updateCartSession(oldSessionId: string): Promise<void> {
		this.setLoading(true)
		const newCart = await this.dependencies.updateCartSession(oldSessionId)
		console.log('updateCartSession newCart', newCart)
		await this.setCart(newCart)
		this.setLoading(false)
	}

	@action
	async relocateCart(newOrderType: string, oldSessionId: string): Promise<void> {
		this.setLoading(true)
		const newCart = await this.dependencies.relocateCart(newOrderType, oldSessionId)
		console.log('updateCart newCart', newCart)
		await this.setCart(newCart)
		this.setReady(true)
		this.setLoading(false)
	}

	@action
	async reOrder(orderData: OrderEntity): Promise<string | undefined> {
		const canReOrder = await this.dependencies.canReOrder(orderData)
		if (canReOrder) {
			const menuPath = await this.dependencies.initSessionFromReOrder(orderData)
			if (!menuPath) {
				return undefined
			}
			const locale = this.dependencies.getUserLocale()
			const newCartItems = orderData.courseList.map((item) => transformItem(item, locale))
			this.setLoading(true)
			const newCart = await this.dependencies.reOrder(orderData.orderType, newCartItems)
			console.log('reOrder newCart', newCart)
			await this.setCart(newCart)
			this.setLoading(false)

			return menuPath
		}

		return undefined
	}

	async dryCartRelocation(
		newOrderType: string,
		oldSessionId: string
	): Promise<{
		cartModified: boolean
	}> {
		return this.dependencies.dryCartRelocation(newOrderType, oldSessionId)
	}

	@action
	async addItem(item: CartItem): Promise<boolean> {
		this.setLoading(true)
		const newCart = await this.dependencies.addItem(item)
		console.log('addItem newCart', newCart)
		await this.setCart(newCart)
		this.setLoading(false)
		this.setReady(true)
		return newCart && newCart.changes.filter((change) => change.type === CartChangeType.ADD_ITEM).length > 0
	}

	@action
	async removeItem(itemId: string, index: number) {
		this.setLoading(true)
		const newCart = await this.dependencies.removeItem(itemId, index)
		console.log('removeItem newCart', newCart)
		await this.setCart(newCart)
		this.setLoading(false)
		this.setReady(true)
	}

	@action
	async updateItem(index: number, item: CartItem) {
		this.setLoading(true)
		const newCart = await this.dependencies.updateItem(index, item)
		console.log('updateItem newCart', newCart)
		await this.setCart(newCart)
		this.setLoading(false)
		this.setReady(true)
	}

	@action
	async addCoupon(coupon: Coupon): Promise<void> {
		this.setLoading(true)
		const newCart = await this.dependencies.addCoupon(coupon)
		console.log('addDiscount newCart', newCart)
		await this.setCart(newCart)
		this.setLoading(false)
		this.setReady(true)
	}

	@action
	async removeCoupon(code: string): Promise<void> {
		this.setLoading(true)
		const newCart = await this.dependencies.removeCoupon(code)
		console.log('removeDiscount newCart', newCart)
		await this.setCart(newCart)
		this.setLoading(false)
		this.setReady(true)
	}

	generateUpsellsArray = (sections: MenuSection[], upsells: MenuUpsells): string[] => {
		if (isEmpty(upsells)) {
			return []
		}

		const cartItems = Object.values(this.items).flat()

		const upsellsItems = new Set<string>()

		;[...upsells.onMenu].forEach(upsellsItems.add, upsellsItems)

		// Add upsells that are relevant for sections in the cart
		const upsellsSectionKeys = Object.keys(upsells?.onSections || [])
		let upsellsItemsOfSection: string[] = []
		if (!isEmpty(upsellsSectionKeys)) {
			const sectionItems = upsellsSectionKeys.map((sectionKey) => {
				const { itemIds } = sections.find(({ id }) => sectionKey === id) ?? ({ itemIds: [] } as { itemIds: string[] })
				return {
					sectionKey,
					itemIds,
				}
			})

			const sectionKeysForCartItems = uniq(
				cartItems.reduce((acc, { itemId: cartItemId }) => {
					const secObj = sectionItems.find(({ itemIds }) => itemIds.includes(cartItemId))
					if (secObj) {
						acc.push(secObj.sectionKey)
					}
					return acc
				}, [] as string[])
			)
			upsellsItemsOfSection = sectionKeysForCartItems.flatMap((secId) => upsells.onSections[secId])
		}

		// Add upsells that are relevant for specific items in the cart
		const specificItems = cartItems.flatMap(({ itemId }) => upsells?.onItems?.[itemId] || [])
		;[...specificItems, ...upsellsItemsOfSection].forEach(upsellsItems.add, upsellsItems)

		return Array.from(upsellsItems)
	}

	@action
	getUpsell = ({
		sections,
		upsells,
		items: menuItems,
	}: {
		sections: MenuSection[]
		upsells: MenuUpsells
		items: Record<string, MenuItem>
	}): MenuItem | undefined => {
		const uniqueUpsellsItems = this.generateUpsellsArray(sections, upsells)
		while (!isEmpty(uniqueUpsellsItems)) {
			const randomIndex = Math.floor(Math.random() * uniqueUpsellsItems.length)
			const item = uniqueUpsellsItems[randomIndex]

			if (menuItems[item]) {
				return menuItems[item]
			}
			uniqueUpsellsItems.splice(randomIndex, 1)
		}
	}
}
