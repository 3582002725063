import { staticImplements } from 'utils/types'
import type { CartDependencies, SaveCartPayload } from './types'
import type { ShowSnackbarProps } from 'mobx/Infra/Infra.type'
import Infra from 'mobx/Infra'
import type { AxiosError, Method } from 'axios'
import type { LanguageLocale } from 'utils/utils'
import { sendRequest } from 'utils/utils'
import Account from 'mobx/Account'
import User from 'mobx/User'
import type { Coupon } from 'shared-types/Coupon'
import type { CartItem, Cart, ServerErrorResponse, CartOrderLockErrorResponse, CartErrorResponse } from 'shared-types/Cart'
import { ErrorCode } from 'shared-types/Cart'
import type { OrderEntity } from 'types/OrderEntity'
import ItemAdditions from 'mobx/ItemAdditions'
import type CouponsStore from 'mobx/Coupons/store'
import { codeToLocale, getTranslatedTextByKey } from 'utils/language'
import { canReOrder, initSessionFromReOrder } from 'utils/cartUtils/cartUtils'
import type { NextRouter } from 'next/router'
import Application from 'mobx/Application'
import { SESSION_EXPIRED } from 'utils/constants'
import { queueableSendRequest } from 'utils/requestsQueue'

@staticImplements<CartDependencies>()
export default class CartRepository {
	static couponsStore: CouponsStore | null = null

	static router: NextRouter | null = null

	static setRouter(router: NextRouter): void {
		this.router = router
	}

	static setCouponsStore(couponsStore: CouponsStore): void {
		this.couponsStore = couponsStore
	}

	private static getStoreId(): string {
		return localStorage.getItem('storeId') || ''
	}

	static getUserLocale(): LanguageLocale {
		const code = User?.preferredLanguage || 'en'
		return codeToLocale[code]
	}

	private static async fetch<T = void>(
		url: string,
		method: Method,
		body?: unknown,
		queryParams?: string | string[][] | Record<string, string> | URLSearchParams
	): Promise<T> {
		const baseUrl = `${Infra.appParams.wruec}/v1/tenants/${Infra.appParams.c}/carts`

		let fullUrl = `${baseUrl}${url}`

		if (queryParams) {
			const params = new URLSearchParams(queryParams)
			fullUrl += `?${params.toString()}`
		}

		const authHeaders = await Account.getAuthorizationHeader(Application.backendChannel)
		const headers = {
			...authHeaders,
			'Tictuk-session-id': User?.session?._id || '',
			locale: this.getUserLocale(),
			'Tictuk-store-id': this.getStoreId(),
		}

		return queueableSendRequest(sendRequest)(false, fullUrl, method, body, headers).catch((e: AxiosError<ServerErrorResponse>) => {
			const errorData = e.response?.data
			console.error('errorData', errorData)

			if (errorData && 'code' in errorData) {
				if (errorData.code === ErrorCode.SET_ITEMS_ERROR) {
					Infra.setNotification({
						open: true,
						message: errorData.message,
						onClose: () => {
							Infra.closeNotification()
						},
						okAction: () => {
							Infra.closeNotification()
						},
					})
					return { success: false }
				}
				if (errorData.code === ErrorCode.SESSION_EXPIRED) {
					sessionStorage.setItem(SESSION_EXPIRED, 'true')
					this.router?.push('/')
					return
				}
				const { message } = errorData as CartErrorResponse
				const errorMessage =
					errorData.code === ErrorCode.COUPON_ITEM_COUPON_NOT_VALID
						? getTranslatedTextByKey(
								'itemAvailabilityNotAvailable',
								'The item %itemName% is no longer available, please select something else.'
						  ).replace('%itemName%', (body as { coupon: Coupon })?.coupon?.title[this.getUserLocale()] ?? 'en_US')
						: message
				Infra.setNotification({
					open: true,
					message: errorMessage,
					onClose: () => {
						Infra.closeNotification()
					},
					okAction: () => {
						Infra.closeNotification()
					},
				})
				if (errorData.code === ErrorCode.CART_ORDER_LOCK && 'cart' in errorData && 'redirectURL' in errorData) {
					const { redirectURL } = errorData as CartOrderLockErrorResponse
					const newUrl = redirectURL.replace(/^.*?(?=\/order-confirmation)/, window.location.origin)
					this.router?.push(newUrl)
					Infra.closeNotification()
					return errorData.cart
				}
			} else {
				// Fallback for unknown errors
				Infra.setNotification({
					open: true,
					message: 'An unexpected error occurred',
					onClose: () => {
						Infra.closeNotification()
					},
					okAction: () => {
						Infra.closeNotification()
					},
				})
			}

			// throw new Error(errorData?.message)
		}) as Promise<T>
	}

	static async saveCart(payload: SaveCartPayload): Promise<{ success: boolean }> {
		return this.fetch<{ success: boolean }>('/save', 'POST', payload)
	}

	static async setCartDiscounts(coupons: Coupon[]): Promise<void> {
		await this.couponsStore?.setAppliedCoupons(coupons)
	}

	static getCartDiscounts(): Coupon[] {
		return this.couponsStore?.appliedCoupons ?? []
	}

	static getCartDiscountsWithoutGovernment(): Coupon[] {
		return this.couponsStore?.appliedCouponsWithoutGovernment ?? []
	}

	static getCoupons(): Coupon[] {
		return this.couponsStore?.coupons ?? []
	}

	static async fetchCart(): Promise<Cart> {
		return this.fetch<Cart>(`/`, 'GET')
	}

	static async resetCart(): Promise<Cart> {
		return this.fetch<Cart>(`/`, 'DELETE')
	}

	static async relocateCart(newOrderType: string, oldSessionId: string): Promise<Cart> {
		return this.fetch<Cart>(`/`, 'PUT', { orderType: newOrderType, oldSessionId })
	}

	static async updateCartSession(oldSessionId: string): Promise<Cart | null> {
		if (!this.getStoreId()) {
			return null
		}
		return this.fetch<Cart>(`/session`, 'PUT', { oldSessionId })
	}

	static async reOrder(orderType: string, courseList: CartItem[]): Promise<Cart> {
		return this.fetch<Cart>(`/reorder`, 'POST', { orderType, courseList })
	}

	static async dryCartRelocation(newOrderType: string, oldSessionId: string): Promise<{ cartModified: boolean }> {
		return this.fetch<{ cartModified: boolean }>(`/relocation/validation`, 'POST', { orderType: newOrderType, oldSessionId })
	}

	static async addItem(item: CartItem): Promise<Cart> {
		return this.fetch<Cart>(`/items`, 'POST', { item })
	}

	static async removeItem(itemId: string, index: number): Promise<Cart> {
		return this.fetch<Cart>(`/items`, 'DELETE', {
			index,
			itemId,
		})
	}

	static async updateItem(index: number, item: CartItem): Promise<Cart> {
		return this.fetch<Cart>(`/items`, 'PUT', {
			index,
			item,
		})
	}

	static addCoupon(coupon: Coupon): Promise<Cart> {
		return this.fetch<Cart>(`/coupons`, 'POST', { coupon })
	}

	static removeCoupon(code: string): Promise<Cart> {
		return this.fetch<Cart>(`/coupons`, 'DELETE', { code })
	}

	static showSnackbar(props: ShowSnackbarProps): void {
		Infra.showSnackbar(props)
	}

	static canReOrder(orderData: OrderEntity) {
		return canReOrder(orderData)
	}

	static initSessionFromReOrder(orderData: OrderEntity) {
		return initSessionFromReOrder(orderData)
	}

	static postInit(item: CartItem, editItem: boolean): void {
		ItemAdditions.postInit(item, editItem)
	}

	static showError(message: string): void {
		Infra.setNotification?.({
			open: true,
			title: message,
			message: '',
			onClose: () => {
				Infra.closeNotification()
			},
			okAction: () => {
				Infra.closeNotification()
			},
		})
	}
}
